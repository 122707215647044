<script lang="ts" setup>
const {
  public: { googlePlayLink },
} = useRuntimeConfig()
</script>

<template>
  <NuxtLink
    v-if="$slots.custom"
    :to="googlePlayLink"
    target="_blank"
    aria-label="Belong in Google Play"
  >
    <slot name="custom" />
  </NuxtLink>

  <NuxtLink
    v-else
    class="border-2 border-solid border-white rounded-full flex flex-row items-center gap-2.5 px-3.5 py-2.5 h-14 px-10 justify-center uppercase whitespace-pre"
    :to="googlePlayLink"
    target="_blank"
    aria-label="Belong in Google Play"
  >
    <i class="i-belong:google-play w-5 h-5" />
    <p class="uppercase font-500 text-sm tracking-wide">Google Play</p>
  </NuxtLink>
</template>
