<script lang="ts" setup>
const {
  public: { appStoreLink },
} = useRuntimeConfig()
</script>

<template>
  <NuxtLink
    v-if="$slots.custom"
    :to="appStoreLink"
    target="_blank"
    aria-label="Belong in AppStore"
  >
    <slot name="custom" />
  </NuxtLink>

  <NuxtLink
    v-else
    class="border-2 border-solid border-white rounded-full flex flex-row justify-center items-center gap-2.5 px-3.5 py-2.5 h-14 px-5"
    :to="appStoreLink"
    target="_blank"
    aria-label="Belong in AppStore"
  >
    <i class="i-belong:appstore w-5 h-5" />
    <p class="uppercase font-500 text-sm tracking-wide">App Store</p>
  </NuxtLink>
</template>
